import React from "react";

export default function Contact() {
  return (
    <section className="careerInfo">
      <div className="auto__container">
        <div className="careerInfo__inner">
          <h2
            className="wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".2s"
          >
            Our contacts
          </h2>
          <div className="careerInfo__inner-row">
            <div className="careerInfo__item">
              <h4
                className="wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".3s"
              >
                Feel free to contact us:
              </h4>
              <ul
                className="wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".4s"
              >
                <li>
                  Support: 
                  <a href="mailto:support@game-garden.com"> support@game-garden.com</a>
                </li>
                <li>
                  Job: 
                  <a href="mailto:job@game-garden.com"> job@game-garden.com</a>
                  <br />
                </li>
                <li>
                  Business: 
                  <a href="mailto:partner@game-garden.com"> partner@game-garden.com</a>
                </li>
              </ul>

              <h4
                className="wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".5s"
              >
                Follow us:
              </h4>
              <div
                className="socials wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".6s"
              >
                <a href="https://www.facebook.com/GameGardenGroup" className="social">
                  <img src="images/icons/facebook.svg" alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/game_garden/" className="social">
                  <img src="images/icons/instagram.svg" alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/3673958/" className="social">
                  <img src="images/icons/linkedin.svg" alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div className="careerInfo__item"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
