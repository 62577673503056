export const RoadmapModul = [
  {
    id: "1",
    date: "2009",
    text: (
      <>
        Foundation <br />
        date
      </>
    ),
  },
  {
    id: "2",
    date: "2011",
    text: <>The first million players</>,
  },
  {
    id: "3",
    date: "2020",
    text: <>Our employees work in 6 countries around the world</>,
  },
  {
    id: "4",
    date: "?",
    text: (
      <>
        What <br />
        is next?
      </>
    ),
  },
  {
    id: "5",
    date: "2010",
    text: <>First released project</>,
  },
  {
    id: "6",
    date: "2019",
    text: <>The studio turned 10 years old</>,
  },
  {
    id: "7",
    date: "2022",
    text: <>100 employees in Game Garden</>,
  },
];
