import React from "react";

export default function Intro() {
  return (
    <section className="intro">
      <div className="auto__container">
        <div className="intro__inner">
          <h1>
            <span
              className="wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".2s"
            >
              WE
              <div className="bee">
                <img src="images/bee.png" alt="bee" />
              </div>
            </span>
            <span
              className="wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".4s"
            >
              CULTIVATE
            </span>
            <span
              className="wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              FUN!
            </span>
          </h1>
        </div>
      </div>
      <div
        className="intro__group wow fadeIn"
        data-wow-duration=".6s"
        data-wow-delay=".6s"
      >
        <img src={process.env.PUBLIC_URL + "images/introGroup.png"} alt="introGroup" />
        <img
          className="mobile"
          src={process.env.PUBLIC_URL + "images/introMobile.png"}
          alt="introMobile"
        />
      </div>
    </section>
  );
}
