import React, { useEffect } from "react";
import { getGame, getRandom3 } from "../../Components/GameModul";
import GameItem from "../../Components/GameItem";
import { useParams } from "react-router-dom";

export default function GameDetail({ setDetail }) {
  const { gameId } = useParams();
  const gameData = React.useMemo(() => getGame(gameId), [gameId]);
  const moreGames = getRandom3(gameId);
  useEffect(() => {
    setDetail(true);
    return () => setDetail(false);
  }, []);

  return (
    <>
      <section className="detail">
        <div className="auto__container">
          <div className="detail__inner">
            <div
              className="detail__inner-image wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              <img src={process.env.PUBLIC_URL + gameData.image} alt="game" />
            </div>
            <div className="detail__inner-content">
              <h2
                className="wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".2s"
              >
                {gameData.name}
              </h2>
              <h4
                className="wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".4s"
              >
                {gameData.slogan}
              </h4>
              <ul
                className="wow fadeIn"
                data-wow-duration=".6s"
                data-wow-delay=".6s"
              >
                {gameData.list.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
              <div
                className="detail__btns wow fadeInUp"
                data-wow-duration=".6s"
                data-wow-delay=".6s"
              >
                {gameData.google && (
                  <a href={gameData.google} className="os">
                    <img
                      src={process.env.PUBLIC_URL + "/images/google.png"}
                      alt="google"
                    />
                  </a>
                )}
                {gameData.apple && (
                  <a href={gameData.apple} className="os">
                    <img
                      src={process.env.PUBLIC_URL + "/images/apple.png"}
                      alt="apple"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="game detail--game">
        <div className="auto__container">
          <div className="game__inner">
            <h2
              className="wow fadeInUp"
              data-wow-duration=".6s"
              data-wow-delay=".2s"
            >
              Other games
            </h2>
            <div className="game__inner-row">
              {moreGames.map((item, index) => (
                <GameItem {...item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
