import React from "react";
import { Link } from "react-router-dom";

export default function GameItem(props) {
  return (
    <Link
      to={"/games/" + props.id}
      className="gameItem wow fadeIn"
      data-wow-duration=".6s"
      data-wow-delay={0.2 * props.index + "s"}
    >
      <div className="gameItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="game" />
      </div>
      <h4>{props.name}</h4>
    </Link>
  );
}
