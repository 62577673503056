export const WorkModul = [
  {
    id: "1",
    title: "Work from Anywhere",
    text: "It doesn't matter to us which country you work from",
    image: "/images/icons/work.png",
  },
  {
    id: "2",
    title: "Personality Respect",
    text: "We provide an individual approach to each employee",
    image: "/images/icons/heart.png",
  },
  {
    id: "3",
    title: "Investment in Employees",
    text: "We are happy to encourage and financially compensate your desire to grow and develop",
    image: "/images/icons/corp.png",
  },
  {
    id: "4",
    title: "Work as Pleasure",
    text: "We strive to make the game development process enjoyable for everyone involved and create games that players enjoy",
    image: "/images/icons/cake.png",
  },
  {
    id: "5",
    title: "Professional Growth",
    text: "We regularly organize corporate meetings for our employees and take part in various hackathons, conferences and meetups",
    image: "/images/icons/growth.png",
  },
  {
    id: "6",
    title: "Social Care",
    text: "We participated in the development and support of significant social projects",
    image: "/images/icons/social.png",
  },

];
