import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Base/Header";
import Home from "./Pages/Home/Home";
import Games from "./Pages/Games/Games";
import WOW from "wowjs";
import GameDetail from "./Pages/Games/GameDetail";
import Contact from "./Pages/Contact/Contact";
import Career from "./Pages/Career/Career";
import CareerDetail from "./Pages/Career/CareerDetail";
import CareerOops from "./Pages/Career/CareerOops";

function App() {
  const [index, setIndex] = useState(false);
  const [detail, setDetail] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // setMenu(false);
    // document.body.classList.remove("active");
  }, [location]);
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    }).init();
  }, [location]);

  return (
    <>
      <Header index={index} location={location} detail={detail} />
      <main className={index ? "" : "wrapper"}>
        <Routes>
          {/* <Route path="*" index element={<Account setAuth={setAuth} />} /> */}
          <Route path="" index element={<Home setIndex={setIndex} />} />
          <Route path="/games" element={<Games />} />
          <Route
            path="/games/:gameId"
            element={<GameDetail setDetail={setDetail} />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career-oops" element={<CareerOops />} />

          <Route path="/career" element={<Career />} />
          <Route path="/career/:careerId" element={<CareerDetail />} />
        </Routes>
      </main>
    </>
  );
}
export default App;
