import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-col">
            <h6>CONTACT US</h6>
            <p className="sm">
              Support: 
              <a href="mailto:support@game-garden.com"> support@game-garden.com</a>
              <br />
              Job: 
              <a href="mailto:job@game-garden.com"> job@game-garden.com</a>
              <br />
              Business:
              <a href="mailto:partner@game-garden.com"> partner@game-garden.com</a>
            </p>
          </div>
          <div className="footer__inner-col">
            <h6>
              548 Market Street #48586<br />
              San Francisco, California, 94104-5401<br />
              <br />
              +1 (415) 527 0140 
            </h6>
          </div>
          <div className="footer__inner-col">
            <div className="footer__inner-links">
              <a href="https://game-garden.com/files/settings/11/ggllceula.pdf" className="footer__inner-link">
                EULA
              </a>
              <a href="https://game-garden.com/files/settings/10/ggllcprivacypolicy.pdf" className="footer__inner-link">
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="footer__inner-col">
            <div className="footer__inner-row">
              <div className="socials">
                <a href="https://www.facebook.com/GameGardenGroup" className="social">
                  <img src="images/icons/facebook.svg" alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/game_garden/" className="social">
                  <img src="images/icons/instagram.svg" alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com/company/3673958/" className="social">
                  <img src="images/icons/linkedin.svg" alt="LinkedIn" />
                </a>
              </div>
              <p className="sm">© Game Garden, 2009-2023</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
