export const CareerModul = [
  {
    id: "1",
    title: "New vacancies",
    listTitle1: "Are comming soon!",
    listText1:[
    "We're constantly growing.",
    "It seems there is no open vacencies at the time...",
    "But if you are great at making games feel free to send us email:",
    "Job@game-garden.com",
    "Stay tuned!",
    ],
    // listTitle2: "What will you do:",
    // listText2: [
    //   "setting up the game balance of the core and metagame;",
    //   "drawing up the balance sheet;",
    //   " balance design for new and old features, optimization of offers;",
    //   "assistance in AB testing.",
    // ],
    // listTitle3: "We offer:",
    // listText3: [
    //   "direct participation in the development of the project about detective investigations;",
    //   "an open atmosphere in which initiative and responsibility are welcomed;",
    //   "work from home or in a lamp office with a seating area and soft warm blankets;",
    //   "official employment;",
    //   "decent salary;",
    //   "a loyal system of day-offs and vacations.",
    // ],
  },
  // {
  //   id: "2",
  //   title: (
  //     <>
  //       2D <br />
  //       Artist
  //     </>
  //   ),
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "3",
  //   title: (
  //     <>
  //       Balance Game <br />
  //       Designer
  //     </>
  //   ),
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Balance Game Designer",
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "5",
  //   title: (
  //     <>
  //       2D <br />
  //       Artist
  //     </>
  //   ),
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "6",
  //   title: "Balance Game Designer",
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "7",
  //   title: "Balance Game Designer",
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "8",
  //   title: (
  //     <>
  //       2D <br />
  //       Artist
  //     </>
  //   ),
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
  // {
  //   id: "9",
  //   title: "Balance Game Designer",
  //   listTitle1: "We expect from you at least:",
  //   listText1: [
  //     "2 years of experience working on similar functionality;",
  //     "experience of successful projects with specific cases;",
  //     "clear understanding of the main metrics of the product;",
  //     "skills of working with analytical tools (tables, analytics, hypotheses, AB-tests).",
  //     " experience of successful projects with specific cases;",
  //   ],
  //   listTitle2: "What will you do:",
  //   listText2: [
  //     "setting up the game balance of the core and metagame;",
  //     "drawing up the balance sheet;",
  //     " balance design for new and old features, optimization of offers;",
  //     "assistance in AB testing.",
  //   ],
  //   listTitle3: "We offer:",
  //   listText3: [
  //     "direct participation in the development of the project about detective investigations;",
  //     "an open atmosphere in which initiative and responsibility are welcomed;",
  //     "work from home or in a lamp office with a seating area and soft warm blankets;",
  //     "official employment;",
  //     "decent salary;",
  //     "a loyal system of day-offs and vacations.",
  //   ],
  // },
];
export const getCareer = (careerId, arr = CareerModul) => {
  return arr.find((item) => item.id === careerId);
};
