import React, { useEffect } from "react";
import Intro from "./components/Intro";
import Roadmap from "./components/Roadmap/Roadmap";
import Games from "./components/Games";
import Work from "./components/Work/Work";
import Footer from "../../Base/Footer";

export default function Home({ setIndex }) {
  useEffect(() => {
    setIndex(true);
    return () => setIndex(false);
  }, []);

  return (
    <>
      <Intro />
      <Roadmap />
      <Games />
      <Work />
      <Footer />
    </>
  );
}
