import React from "react";
import { WorkModul } from "./WorkModul";
import { Link } from "react-router-dom";

export default function Work() {
  return (
    <section className="work">
      <div className="auto__container">
        <div className="work__inner">
          <h2
            className="wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".4s"
          >
            Work in our company is
          </h2>
          <div className="work__inner-row">
            <div
              className="workItem wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".2s"
            >
              <div className="workItem__icon">
                <img src="images/icons/team.png" alt="" />
              </div>
              <h4>Friendly Environment</h4>
              <p>
                We value openness and sincerity in teamwork
              </p>
            </div>
            {WorkModul.map((item, index) => {
              return (
                <div
                  className="workItem wow fadeIn"
                  key={index}
                  data-wow-duration=".6s"
                  data-wow-delay={0.3 * item.index + "s"}
                >
                  <div className="workItem__icon">
                    <img src={process.env.PUBLIC_URL + item.image} alt="icon" />
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
          <div
            className="work__btn wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".4s"
          >
            <Link to="/career" className="button primary">
              OUR VACANCIES
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
