import React from "react";
import { useParams } from "react-router-dom";
import { getCareer } from "../../Components/CareerModul";

export default function CareerDetail() {
  const { careerId } = useParams();
  const careerData = React.useMemo(() => getCareer(careerId), [careerId]);

  return (
    <section className="careerInfo">
      <div className="auto__container">
        <div className="careerInfo__inner">
          <h2 className="wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".2s">
            {careerData.title}
          </h2>
          <div className="careerInfo__inner-row">
            <div
              className="careerInfo__inner-col wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".4s"
            >
              <div className="careerInfo__item">
                <h4> {careerData.listTitle1}</h4>
                <ul>
                  {careerData.listText1.map((item1, index) => {
                    return <li key={index}>{item1}</li>;
                  })}
                </ul>
              </div>
              <div className="careerInfo__item">
                <h4> {careerData.listTitle2}</h4>
                <ul>
                  {careerData.listText2.map((item2, index) => {
                    return <li key={index}>{item2}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div
              className="careerInfo__inner-col wow fadeIn"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              <div className="careerInfo__item">
                <h4> {careerData.listTitle3}</h4>
                <ul>
                  {careerData.listText3.map((item3, index) => {
                    return <li key={index}>{item3}</li>;
                  })}
                </ul>
              </div>
              <div className="careerInfo__item">
                <a href="#" className="button primary">
                  {" "}
                  IT’S ME{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
