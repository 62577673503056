import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header({ index, detail }) {
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  return (
    <header className={"header " + (index ? "light" : "")}>
      <div className="auto__container">
        <div className="header__inner">
          <Link
            to="/"
            className={"header__inner-logo " + (detail ? "detailLogo" : "")}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                (!index ? "/images/logoGreen.svg" : "/images/logo.svg")
              }
              alt="logo"
            />
            {detail && (
              <img
                className="white"
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            )}
          </Link>
          <nav
            className={"nav " + (menu ? "active" : "")}
            id="menu"
            onClick={close}
          >
            <div className="nav__inner">
              <div className="nav__inner-links">
                <Link to="games" className="nav__inner-link">
                  Games
                </Link>
                <Link to="career" className="nav__inner-link">
                  Career
                </Link>
                <Link to="contact" className="nav__inner-link">
                  Contacts
                </Link>
              </div>

              <div className="flower">
                <div className="flower__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/flower.png"}
                    alt="flower"
                  />
                </div>
                <div className="flower__bee">
                  <img
                    src={process.env.PUBLIC_URL + "/images/bee.png"}
                    alt="bee"
                  />
                </div>
              </div>
            </div>
          </nav>
          {index ? (
            <div
              className={"burger " + (menu ? "active" : "")}
              onClick={() => {
                setMenu(!menu);
              }}
            >
              <span></span>
            </div>
          ) : (
            <Link to="" className="back">
              <img
                src={
                  process.env.PUBLIC_URL +
                  (!detail
                    ? "/images/icons/back.svg"
                    : "/images/icons/backW.png")
                }
                alt="back"
              />
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
