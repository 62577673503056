import React from "react";
import GameItem from "../../../Components/GameItem";
import { Link, useParams } from "react-router-dom";
import { getGame, getRandom3 } from "../../../Components/GameModul";

export default function Games() {
  const { gameId } = useParams();
  const gameData = React.useMemo(() => getGame(gameId), [gameId]);
  const moreGames = getRandom3(gameId);

  return (
    <section className="game index--game">
      <div className="auto__container">
        <div className="game__inner">
          <h2
            className="wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".2s"
          >
            Other games
          </h2>
          <div className="game__inner-row">
            {moreGames.map((item, index) => (
              <GameItem {...item} key={index} />
            ))}
          </div>
          <div
            className="game__btn wow fadeIn"
            data-wow-duration=".6s"
            data-wow-delay=".4s"
          >
            <Link to="/games" className="button primary">
              MORE GAMES
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
