import React from "react";
import { Link } from "react-router-dom";
import { arrowRight } from "../Base/SVG";

export default function CareerItem(props) {
  return (
    <Link
      to={"/career/" + props.id}
      className="careerItem wow fadeIn"
      data-wow-duration=".6s"
      data-wow-delay={0.2 * props.index + "s"}
    >
      <h3>{props.title}</h3>
      <div className="careerItem__more">
        <h5>Learn more</h5>
        {arrowRight}
      </div>
    </Link>
  );
}
