import React from "react";

export default function RoadmapItem(props) {
  return (
    <div
      className="roadmapItem wow fadeIn"
      data-wow-duration=".6s"
      data-wow-delay={0.1 * props.index + "s"}
    >
      <h2>{props.date}</h2>
      <p>{props.text}</p>
    </div>
  );
}
