import React from "react";
import GameItem from "../../Components/GameItem";
import { GameModul } from "../../Components/GameModul";

export default function Games() {
  return (
    <section className="game">
      <div className="auto__container">
        <div className="game__inner">
          <h2
            className="wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".2s"
          >
            Other games
          </h2>
          <div className="game__inner-row">
            {GameModul.map((item, index) => {
              return <GameItem {...item} key={index} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
