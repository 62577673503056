import React from "react";
import { RoadmapModul } from "./RoadmapModul";
import RoadmapItem from "./RoadmapItem";

export default function Roadmap() {
  return (
    <section className="roadmap">
      <div className="auto__container">
        <div className="roadmap__header">
          <h3
            className="sm wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".2s"
          >
            Games that become hits and quickly win the love of users around the
            world.
          </h3>

          <h3
            className="sm wow fadeInUp"
            data-wow-duration=".6s"
            data-wow-delay=".4s"
          >
            For this, we boldly experiment with settings and genres, study
            trends and look for new niches.
          </h3>
        </div>
      </div>
      <div className="roadmap__inner">
        <div className="roadmap__inner-row">
          {RoadmapModul.map((item, index) => {
            return <RoadmapItem {...item} index={index} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
}
