export const GameModul = [
    {
    id: "soul-spa",
    name: "Soul SPA",
    slogan: "Build and manage your own Spa Empire!",
    image: "/images/games/game8.png",
    list: [
      "Find peace and happiness in your Spa Salons",
      "Match customers with service requests to earn money and make them happy and full of gratitude",
      "Improve and decorate your Salons to attract new customers",
      "Use helpful Boosters to powerup and maximize your earnings",
      "Remember, every decision and every choice matters",
      "Use helpful Boosters to powerup and maximize your earnings",
      "Become a master detective with Criminal Stories",
    ],
    apple:
      "https://apps.apple.com/no/app/soul-spa-hot-aura/id1665673486",
    google:
      "https://play.google.com/store/apps/details?id=com.gamegarden.ss&hl=ru&gl=US",
  },
  {
    id: "criminal-stories",
    name: "Criminal Stories",
    slogan: "The best way to reveal your investigation skills",
    image: "/images/games/game1.png",
    list: [
      "Mysterious and difficult criminal cases",
      "Ask victims, interrogate suspects",
      "Customize and dress up your character",
      "Fingerprint analysis, transcript of telephone recordings, dna test, forensic examination - it is a pocket of all instruments that you will own",
      "Remember, every decision and every choice matters",
      "Become a master detective with Criminal Stories",
    ],
    apple:
      "https://apps.apple.com/us/app/criminal-stories-csi-episode/id1587357368",
    google:
      "https://play.google.com/store/apps/details?id=com.gamegarden.cs&hl=en&gl=US",
  },
  {
    id: "florescence",
    name: "Florescence",
    image: "/images/games/game2.png",
    slogan: "Nourish your own garden",
    list: [
      "Become a professional plant nanny: play merge game with rare varieties of flowers.",
      "Make growing plants a successful business",
      "You're a designer: make your family flower shop the best in town.",
      "Upgrade your shop with new furniture, aided by your loyal greenhouse employee.",
      "Your grandma has a dark secret, one she would never tell, and you can't let her get away with that!",
      "Become the talk of the town, the Lord of the Plants! Let them shine!",
    ],
    google:
      "https://play.google.com/store/apps/details?id=com.gamegarden.florescence&hl=en&gl=US",
  },
  {
    id: "love-choise",
    name: "Love Choice",
    image: "/images/games/game3.png",
    slogan: "So what will you do for love? ",
    list: [
      "Choose your interactive stories: drama, fantasy, comedy, or action",
      "Make your storyline full of drama, romance and dreams",
      "Customize and dress up your character",
      "Seek the best way to make a good choices",
      "Remember, every decision and every choice matters",
      "So be romance and find a true love",
    ],
  },
  {
    id: "farmdale",
    name: "Farmdale",
    image: "/images/games/game4.png",
    slogan: "Wonderful adventure in a magical dale",
    list: [
      "A magical world with different characters",
      "Cute animals that will need your care",
      "Hundreds of quests",
      "Dozens of plants and trees ",
      "Production objects and countless recipes  ",
      "Various buildings and improvements",
    ],
    google:
      "https://play.google.com/store/apps/details?id=com.gamegarden.fd&hl=en&gl=US",
    google:
      "https://apps.apple.com/us/app/farmdale-magic-family-farm/id858467950",
  },
  {
    id: "ranchdale",
    name: "Ranchdale",
    image: "/images/games/game5.png",
    slogan: "The possibilities as the farmer-mayor of Ranchdale are endless",
    list: [
      "Build houses and invite villagers to your city, each of them unique and with a helpful profession",
      "Hire villagers on the farm, the pasture, and in eco-factories",
      "Feed animals and harvest crops, to obtain farm products",
      "Use your time-management experience to cook rustic dishes with family recipes",
      "Get to know the citizens better, fulfill their requests, and discover the story of your tiny city",
      "Play mini-games on the Town Fair to improve your workers",
    ],
  },
  {
    id: "fairy-kindom",
    name: "Fairy Kindom",
    image: "/images/games/game6.png",
    slogan: "Build your own kingdom",
    list: [
      "11 unique characters who will need your help",
      "More than 500 different quests",
      "Customize and dress up your character",
      "Dozens of different buildings to construct",
      "Magical collectible pets",
    ],
  },
  {
    id: "fairy-farm",
    name: "Fairy Farm",
    image: "/images/games/game7.png",
    slogan: "The cutest and coziest farm game ever!",
    list: [
      "Discover more than 150 magic plants and trees",
      "Tame hundreds of cute animals",
      "Customize and dress up your magic character",
      "Decorate your farm with numerous enchanted items",
    ],
  },
];

export const getGame = (gameId, arr = GameModul) => {
  return arr.find((item) => item.id === gameId);
};

export const getRandom3 = (excluded) => {
  let result = [];

  while (result.length < 3) {
    let item = GameModul[Math.floor(Math.random() * GameModul.length)];
    let isExist = !!getGame(item.id, result);
    if (item.id !== excluded && !isExist) result.push(item);
  }

  console.log(result);

  return result;
};
