import React from "react";
import CareerItem from "../../Components/CareerItem";
import { CareerModul } from "../../Components/CareerModul";

export default function Career() {
  return (
    <section className="career">
      <div className="auto__container">
        <div className="career__inner">
          <h2
            className="wow fadeIn"
            data-wow-duration=".6s"
            data-wow-delay=".6s"
          >
            Our vacancies
          </h2>
          <div className="career__inner-row">
            {CareerModul.map((item, index) => {
              return <CareerItem {...item} key={index} index={index} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
