import React from "react";

export default function CareerOops() {
  return (
    <section className="career career--oops">
      <div className="auto__container">
        <div className="career__inner">
          <div className="career__oops">
            <h2
              className="wow fadeInUp"
              data-wow-duration=".6s"
              data-wow-delay=".2s"
            >
              Our vacancies
            </h2>
            <h4
              className="wow fadeInUp"
              data-wow-duration=".6s"
              data-wow-delay=".4s"
            >
              Oops it seems we don’t have any open vacancies.
            </h4>
            <h4
              className="reg wow fadeInUp"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              But if you <strong>really</strong> want to join our team you can
              send your cv by clicking right there.
            </h4>
            <div
              className="career__inner-btn wow fadeInUp"
              data-wow-duration=".6s"
              data-wow-delay=".8s"
            >
              <a href="#" className="button primary">
                RIGHT THERE
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="postMan wow fadeIn"
        data-wow-duration=".6s"
        data-wow-delay=".6s"
      >
        <img src="images/postMan.png" alt="" />
      </div>
    </section>
  );
}
